



























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'

import PermissionsMixin from '@/mixins/permissionsMixin'

type ServicePeopleType = 'specialist' | 'visitor'

@Component({
  components: {
    TrashCan16
  }
})
export default class ServiceUsersTable extends Mixins(PermissionsMixin) {
  @Prop({ required: true, type: Array }) people!: Array<any>
  @Prop({ required: true, type: String }) serviceId!: Array<any>
  @Prop({ required: true, type: Boolean }) isPendingInitialVistory!: boolean
  @Prop({ required: true, type: Boolean }) done!: boolean
  @Prop({ required: true, type: String }) type!: ServicePeopleType
  @Prop({ type: Boolean }) hasVacancies!: boolean
  @Prop({ type: Boolean }) isIndependentOrAspiring!: boolean
  filteredData: Array<any> = []

  total = 0

  @Watch('people', { immediate: true })
  onPeopleChange (people: Array<any>) {
    this.filteredData = people
    this.total = people.length
  }

  search (searchString: string) {
    this.filteredData = this.people.filter((person: ServiceUser) => {
      let { name } = person

      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (name.includes(searchString)) {
        return person
      }
    })
  }
}
