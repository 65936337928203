
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PersonCard extends Vue {
  @Prop({ required: true }) name!: string
  @Prop() imageSrc!: string
}
