






























































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import SettingsAdjust16 from '@carbon/icons-vue/es/settings--adjust/16'
import DataCard from '@/partials/components/DataCard.vue'
import User16 from '@carbon/icons-vue/es/user/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'
import { namespace } from 'vuex-class'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import PermissionsMixin from '@/mixins/permissionsMixin'
import ServiceMessages from '@/partials/tables/services/Messages.vue'
import ServiceSurveys from '@/partials/tables/services/Surveys.vue'
import NotificationMixin from '@/mixins/notificationMixin'
import ServiceUserDetailsModal from '@/partials/modals/Services/ServiceUserDetails.vue'
import TakeExamSection from '@/partials/components/Service/TakeExamSection.vue'
import MyDiaryTable from '@/partials/tables/services/Diary.vue'
import PersonCard from '@/partials/components/PersonCard.vue'
import ActivitiesGraph from '@/partials/components/Service/ActivitiesGraph.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import ServiceUsersTable from '@/partials/tables/services/ServiceUsers.vue'

const sessionModule = namespace('session')
const servicesPermissionsModule = namespace('servicesPermissions')

@Component({
  directives: { mask },
  components: {
    SettingsAdjust16,
    User16,
    TrashCan16,
    DataCard,
    ServiceMessages,
    ServiceSurveys,
    ServiceUserDetailsModal,
    TakeExamSection,
    MyDiaryTable,
    PersonCard,
    ActivitiesGraph,
    PasswordConfirmField,
    ValidationObserver,
    ValidationProvider,
    ServiceUsersTable
  }
})
export default class ServicesView extends Mixins(PermissionsMixin, NotificationMixin, ValidatorConfigMixin) {
  @sessionModule.Getter state!: any
  @sessionModule.Getter isCommonUser!: boolean
  @servicesPermissionsModule.Mutation('ADD_PERMISSIONS') addServicePermission!: Function
  hasMessages = true
  hasSurveys = true
  uploadTEOT = []
  uploadAnother = []
  meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  filteredData = []

  service: Record<string, any>= {}
  specialists: Array<Record<string, any>> = []
  visitors: Array<Record<string, any>> = []
  serviceChief: Record<string, any> = {}
  secretaries: Record<string, any> = []
  yesterday = moment().subtract(1, 'days').format('DD/MM')
  today = moment().format('DD/MM')

  selectedServiceUserDetails = {}
  serviceUserDetailsModalIsOpened = false

  vacanciesModalIsOpened = false
  vacanciesForm: Record<string, any> = {
    vacancies: 0,
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  updateServiceVacancies () {
    if (this.vacanciesForm.vacancies < this.specialists.length) return

    this.notification.open = false

    axios.patch(`service/${this.$route.params.id}/vacancies`, this.vacanciesForm)
      .then(() => {
        // const message = response.data.data
        // this.setNotification('success', message)

        this.service.vacancies = this.vacanciesForm.vacancies
        this.service.availableVacancies = this.vacanciesForm.vacancies - this.specialists.length
        this.toggleModal('vacanciesModalIsOpened')
      })
      .catch(err => this.handleUpdateVacanciesError(err))
  }

  handleUpdateVacanciesError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      const errors = response.data.errors

      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.vacanciesForm.errors[key] = value[0]
      })
    }
  }

  clearVacanciesForm () {
    this.vacanciesForm.confirm = ''
    this.vacanciesForm.errors = {
      confirm: ''
    }

    this.$refs.vacanciesObserver.reset()
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  removeServicePersonModalIsOpened = false
  selectedServicePerson: Record<string, any> = {}

  removeServicePersonModal (servicePerson: ServiceUser, role: string) {
    this.selectedServicePerson = servicePerson
    this.selectedServicePerson.role = role
    this.removeServicePersonModalIsOpened = true
  }

  responseMessage = ''
  responseModal = false

  submitRemoveServicePerson () {
    const role = this.selectedServicePerson.role
    const removeForm = {
      users: [this.selectedServicePerson.id],
      isSpecialist: role === 'specialists',
      confirm: this.form.confirm
    }

    axios.delete(`service/${this.$route.params.id}/users/delete`, { data: removeForm })
      .then(response => {
        this.removeServicePersonModalIsOpened = false
        this.responseMessage = response.data.data
        this.$data[role] = this.$data[role].filter((person: ServiceUser) => this.selectedServicePerson.id !== person.id)
        if (role === 'specialists') {
          this.service.availableVacancies++
        }
        this.removeServicePersonModalIsOpened = false
      })
      .catch(err => this.handleDeleteServicePersonError(err))
  }

  handleDeleteServicePersonError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  $refs!: {
    deleteObserver: any;
    vacanciesObserver: any;
  }

  clearDeleteServicePersonError () {
    this.form.confirm = ''
    this.form.errors = {
      confirm: ''
    }

    this.$refs.deleteObserver.reset()
  }

  diaryButtons = {
    fromTheTop: 0
  }

  done = false
  created () {
    axios.get(`/service/${this.$route.params.id}`)
      .then((response) => {
        this.service = camelCaseKeys(response.data.data, { deep: true })
        this.vacanciesForm.vacancies = this.service.vacancies

        this.service.serviceUsers.forEach((serviceUser: any) => {
          console.assert(serviceUser.roles[0] !== undefined, serviceUser)
          if (serviceUser.userId === this.state.user.id) {
            const serviceWithPermission: ServiceWithPermissions = {
              id: serviceUser.serviceId,
              serviceUserId: serviceUser.id,
              permissions: serviceUser.roles[0].permissions
            }
            // Provisorio enquanto API nao devolve resposta correta
            this.service.examSchedules = serviceUser.myExamSchedules
            this.addServicePermission(serviceWithPermission)
          }

          if (serviceUser.roles[0].name === 'Especializando') {
            this.specialists.push({ ...serviceUser.user, serviceUserId: serviceUser.id })
          } else if (serviceUser.roles[0].name === 'Visitante') {
            this.visitors.push({ ...serviceUser.user, serviceUserId: serviceUser.id })
          } else if (serviceUser.roles[0].name === 'Chefe de Serviço') {
            this.serviceChief = { ...serviceUser.user, serviceUserId: serviceUser.id }
          } else if (serviceUser.roles[0].name === 'Secretaria Centro de Serviço') {
            this.secretaries.push({ ...serviceUser.user, serviceUserId: serviceUser.id })
          }
        })
      })
      .finally(() => { this.done = true })
  }

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll)
    })
    const diaryButtons = document.getElementById('diary-buttons')
    if (diaryButtons !== null) {
      this.diaryButtons.fromTheTop = diaryButtons.getBoundingClientRect().top + window.scrollY
    }
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll () {
    const diaryButtons = document.getElementById('diary-buttons')
    if (diaryButtons !== null) {
      if (window.scrollY > this.diaryButtons.fromTheTop && window.innerWidth < 768) {
        diaryButtons.classList.add('fixed', 'top-0', 'z-10', 'w-full', 'items-center', 'bg-white', 'bg-opacity-75', 'inset-x-0')
      } else {
        diaryButtons.classList.remove('fixed', 'top-0', 'z-10', 'w-full', 'items-center', 'bg-white', 'bg-opacity-75', 'inset-x-0')
      }
    }
  }

  get isIndependentOrAspiring () {
    return ['independent', 'aspiring'].includes(this.service.status)
  }
}
