import { render, staticRenderFns } from "./show.vue?vue&type=template&id=721c3493&scoped=true&"
import script from "./show.vue?vue&type=script&lang=ts&"
export * from "./show.vue?vue&type=script&lang=ts&"
import style0 from "./show.vue?vue&type=style&index=0&id=721c3493&scoped=true&lang=scss&"
import style1 from "./show.vue?vue&type=style&index=1&id=721c3493&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721c3493",
  null
  
)

export default component.exports