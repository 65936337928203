




























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ServiceUserDetails extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop() serviceUser!: ServiceUser

  closeModal () {
    this.$emit('close')
  }
}
