



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const takeExamModule = namespace('takeExam')

@Component
export default class TakeExamSection extends Vue {
  @takeExamModule.Action setExamParams!: Function
  @takeExamModule.Action setExamStartedOn!: Function
  @Prop() examSchedules!: Array<ServiceExamSchedule>
  @Prop() serviceUserId!: string
  @Prop() serviceId!: string

  async goToStart (examSchedule: ServiceExamSchedule) {
    this.setExamParams(examSchedule)
      .then(
        this.$router.push({
          name: 'TakeExamStart',
          params: { examScheduleServiceUserId: examSchedule.id }
        })
      )
  }
}
