




































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import moment from 'moment'

import DeleteDiaryModal from '@/partials/modals/Services/Diary/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    TrashCan16,
    DeleteDiaryModal
  }
})
export default class MyDiaryTable extends Mixins(FlashMessageMixin) {
  @Prop({ required: true }) serviceUserId!: string
  myDiary: any = []
  selectedDiaryEntry: DiaryEntry = {
    id: '',
    performedAt: '',
    activity: {
      name: ''
    }
  }

  deleteModalIsOpened = false

  created () {
    this.getMyDiary()
  }

  submitted (payload: string) {
    this.deleteModalIsOpened = false

    const flashMessage = {
      message: payload,
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)
    this.getMyDiary()
  }

  getMyDiary () {
    this.myDiary = []
    this.$emit('done', false)
    axios.get(`service/${this.serviceUserId}/diary`)
      .then((response) => {
        const activities = Object.values(camelCaseKeys(response.data.data, { deep: true }))

        activities.forEach((activity: any) => {
          const year = moment(activity.performedAt).year()
          const month = moment(activity.performedAt).format('MMMM')

          if (!this.myDiary[year]) {
            this.myDiary[year] = {}
          }
          if (!this.myDiary[year][month]) {
            this.myDiary[year][month] = []
          }

          this.myDiary[year][month].push(activity)
        })

        this.myDiary = Object.entries(this.myDiary)
      })
      .finally(() => { this.$emit('done', true) })
  }

  async printActivities () {
    const bodyHtml = Array.from(document.getElementsByClassName('print-me'))
      .reduce((acc: string, elem: Element) => {
        return acc + elem.innerHTML
      }, '')

    let stylesHtml = ''
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      stylesHtml += node.outerHTML
    }

    const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

    if (printWindow) {
      printWindow.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${bodyHtml}
          </body>
        </html>`)

      printWindow.document.close()
      await new Promise((resolve) => setTimeout(resolve, 1))
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }
  }
}

